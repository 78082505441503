module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Heptagon 7","short_name":"H7","start_url":"/simulator-pwa","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/images/favicon.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae128105ab5969ff90d0461f02e618ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"gyu5npi"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"de","fallbackLanguage":"en","generateDefaultLanguagePage":false,"localeJsonSourceName":"i18nLocale","localeJsonNodeName":"i18nLocales","siteUrl":"https://h7.de","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/","languages":["de","en"]},{"matchPath":"/jaeger","languages":["de","en"]},{"matchPath":"/behoerden","languages":["de","en"]},{"matchPath":"/produkte","languages":["de","en"]},{"matchPath":"/zubehoer","languages":["de","en"]},{"matchPath":"/veranstaltungen","languages":["de","en"]},{"matchPath":"/simulator","languages":["de","en"]},{"matchPath":"/simulator-pwa","languages":["de","en"]},{"matchPath":"/glossar","languages":["de"]},{"matchPath":"/kontakt","languages":["de","en"]},{"matchPath":"/ueber-uns","languages":["de","en"]},{"matchPath":"/impressum","languages":["de","en"]},{"matchPath":"/rechtliches","languages":["de","en"]},{"matchPath":"/datenschutz","languages":["de","en"]},{"matchPath":"/downloads","languages":["de","en"]},{"matchPath":"/batterieentsorgung","languages":["de","en"]},{"matchPath":"/:lang?/:handle","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NED6S5DD3B"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/simulator-pwa","/en/simulator-pwa"],"appendScript":"/Users/alexanderhoerl/Sites/h7.de/custom-sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
